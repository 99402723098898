export const STRIPE_PRICES = {
  STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_MONTHLY_EXPERIMENTAL_092024:
    'small_business_social_monthly_experimental_092024',
  STRIPE_PLAN_SMALL_BUSINESS_SUITE_MONTHLY_EXPERIMENTAL_092024:
    'small_business_suite_monthly_experimental_092024',
  STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_QUARTERLY_092024: 'small_business_social_quarterly_072024',
  STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_YEARLY_092024: 'small_business_social_yearly_072024',
  STRIPE_PLAN_AGENCY_SOCIAL_QUARTERLY_092024: 'agency_social_quarterly_072024',
  STRIPE_PLAN_AGENCY_SOCIAL_YEARLY_092024: 'agency_social_yearly_072024',
  STRIPE_PLAN_SMALL_BUSINESS_SUITE_QUARTERLY_092024: 'small_business_suite_quarterly_072024',
  STRIPE_PLAN_SMALL_BUSINESS_SUITE_YEARLY_092024: 'small_business_suite_yearly_072024',
  STRIPE_PLAN_AGENCY_SUITE_QUARTERLY_092024: 'agency_suite_quarterly_072024',
  STRIPE_PLAN_AGENCY_SUITE_YEARLY_092024: 'agency_suite_yearly_072024',
  STRIPE_PLAN_CUSTOM_MONTHLY_012023: 'custom_monthly_012023',
  STRIPE_PLAN_CUSTOM_QUARTERLY_012023: 'custom_quarterly_012023',
  STRIPE_PLAN_CUSTOM_BIANNUAL_012023: 'custom_biannual_012023',
  STRIPE_PLAN_CUSTOM_YEARLY_012023: 'custom_yearly_012023',

  // Addons
  STRIPE_PLAN_ADDON_SOCIAL_CHANNEL_QUARTERLY_072024: 'addon_social_channel_quarterly_072024',
  STRIPE_PLAN_ADDON_SOCIAL_CHANNEL_YEARLY_072024: 'addon_social_channel_yearly_072024',
  STRIPE_PLAN_ADDON_UNLIMITED_SOCIAL_POST_QUARTERLY_072024:
    'addon_unlimited_social_post_quarterly_072024',
  STRIPE_PLAN_ADDON_UNLIMITED_SOCIAL_POST_YEARLY_072024:
    'addon_unlimited_social_post_yearly_072024',
  STRIPE_PLAN_ADDON_WEBSITE_CHANNEL_QUARTERLY_072024: 'addon_website_channel_quarterly_072024',
  STRIPE_PLAN_ADDON_WEBSITE_CHANNEL_YEARLY_072024: 'addon_website_channel_yearly_072024',
  STRIPE_PLAN_ADDON_MONTHLY_ARTICLE_QUARTERLY_072024: 'addon_monthly_article_quarterly_072024',
  STRIPE_PLAN_ADDON_MONTHLY_ARTICLE_YEARLY_072024: 'addon_monthly_article_yearly_072024',

  // Ambassadors (only available by contacting sales)
  STRIPE_PLAN_ADDON_AMBASSADOR_CONTACT_QUARTERLY_072024:
    'addon_ambassador_contact_quarterly_072024',
  STRIPE_PLAN_ADDON_AMBASSADOR_CONTACT_YEARLY_072024: 'addon_ambassador_contact_yearly_072024',

  // PowerMode
  STRIPE_PLAN_ADDON_POWERMODE_MONTHLY_EXPERIMENTAL_092024:
    'addon_powermode_monthly_experimental_092024',
  STRIPE_PLAN_ADDON_POWERMODE_QUARTERLY_072024: 'addon_powermode_quarterly_072024',
  STRIPE_PLAN_ADDON_POWERMODE_YEARLY_072024: 'addon_powermode_yearly_072024',

  // Appsumo (2018)
  STRIPE_PLAN_APPSUMO: 'appsumo',
  STRIPE_PLAN_APPSUMO_EXTRA: 'appsumo_extra',

  // White label partner
  STRIPE_PLAN_BRIGHTMINE: 'xperthr',
  STRIPE_PLAN_BRIGHTMINE_STARTER: 'brightmine_starter',
  STRIPE_PLAN_BRIGHTMINE_PRO: 'brightmine_pro',
};

export const APP_SUMO_PLANS = [
  STRIPE_PRICES.STRIPE_PLAN_APPSUMO,
  STRIPE_PRICES.STRIPE_PLAN_APPSUMO_EXTRA,
];

export type STRIPE_PRICES_UNION =
  | typeof STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_MONTHLY_EXPERIMENTAL_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_MONTHLY_EXPERIMENTAL_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_QUARTERLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_YEARLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_AGENCY_SOCIAL_QUARTERLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_AGENCY_SOCIAL_YEARLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_QUARTERLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_YEARLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_AGENCY_SUITE_QUARTERLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_AGENCY_SUITE_YEARLY_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_CUSTOM_MONTHLY_012023
  | typeof STRIPE_PRICES.STRIPE_PLAN_CUSTOM_QUARTERLY_012023
  | typeof STRIPE_PRICES.STRIPE_PLAN_CUSTOM_BIANNUAL_012023
  | typeof STRIPE_PRICES.STRIPE_PLAN_CUSTOM_YEARLY_012023
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_SOCIAL_CHANNEL_QUARTERLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_SOCIAL_CHANNEL_YEARLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_UNLIMITED_SOCIAL_POST_QUARTERLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_UNLIMITED_SOCIAL_POST_YEARLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_WEBSITE_CHANNEL_QUARTERLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_WEBSITE_CHANNEL_YEARLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_MONTHLY_ARTICLE_QUARTERLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_MONTHLY_ARTICLE_YEARLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_AMBASSADOR_CONTACT_QUARTERLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_AMBASSADOR_CONTACT_YEARLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_MONTHLY_EXPERIMENTAL_092024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_QUARTERLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_YEARLY_072024
  | typeof STRIPE_PRICES.STRIPE_PLAN_APPSUMO
  | typeof STRIPE_PRICES.STRIPE_PLAN_APPSUMO_EXTRA
  | typeof STRIPE_PRICES.STRIPE_PLAN_BRIGHTMINE
  | typeof STRIPE_PRICES.STRIPE_PLAN_BRIGHTMINE_STARTER
  | typeof STRIPE_PRICES.STRIPE_PLAN_BRIGHTMINE_PRO;

export const PRIMARY_PRICES = [
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_MONTHLY_EXPERIMENTAL_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_MONTHLY_EXPERIMENTAL_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_YEARLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_YEARLY_092024,
];

export const USER_SEAT_BASE_PRICES: string[] = [
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_MONTHLY_EXPERIMENTAL_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_MONTHLY_EXPERIMENTAL_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_YEARLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_YEARLY_092024,
];

export const PRIMARY_MANAGEABLE_STRIPE_PRICES: string[] = [
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_YEARLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_AGENCY_SOCIAL_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_AGENCY_SOCIAL_YEARLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_YEARLY_092024,
];

export const MANAGEABLE_STRIPE_PRICES: string[] = [
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_MONTHLY_EXPERIMENTAL_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_MONTHLY_EXPERIMENTAL_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SOCIAL_YEARLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_QUARTERLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_SMALL_BUSINESS_SUITE_YEARLY_092024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_SOCIAL_CHANNEL_QUARTERLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_SOCIAL_CHANNEL_YEARLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_UNLIMITED_SOCIAL_POST_QUARTERLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_UNLIMITED_SOCIAL_POST_YEARLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_WEBSITE_CHANNEL_QUARTERLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_WEBSITE_CHANNEL_YEARLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_MONTHLY_ARTICLE_QUARTERLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_MONTHLY_ARTICLE_YEARLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_MONTHLY_EXPERIMENTAL_092024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_QUARTERLY_072024,
  STRIPE_PRICES.STRIPE_PLAN_ADDON_POWERMODE_YEARLY_072024,
];

export const INTERVAL_TYPES = {
  Quarterly: 'quarter',
  Yearly: 'year',
  Monthly: 'month',
  Biannual: 'biannual',
  Ltd: 'ltd',
};

export type INTERVAL_TYPES_UNION =
  | typeof INTERVAL_TYPES.Quarterly
  | typeof INTERVAL_TYPES.Yearly
  | typeof INTERVAL_TYPES.Yearly
  | typeof INTERVAL_TYPES.Monthly
  | typeof INTERVAL_TYPES.Biannual
  | typeof INTERVAL_TYPES.Ltd;

export type STRIPE_CURRENCY_TYPES_UNION = string | 'usd' | 'eur';

export type STRIPE_PAYMENT_METHODS_UNION = 'card' | 'sepa_debit';
